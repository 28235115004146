import classNames from "classnames"
import { graphql, StaticQuery } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import PropTypes from "prop-types"
import React, { Component, createRef } from "react"
import { IconContext } from "react-icons"
import { FaBars, FaTimes } from "react-icons/fa"
import Logo from "../icons/college-optimized-icon.svg"
import "./global.sass"
import "./layout.sass"

class Layout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      navDrawerIsOpen: false
    }
    this.sidebarRef = createRef()
  }

  componentDidMount() {
    document.addEventListener("mouseup", this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener("mouseup", this.handleClickOutside)
  }

  navToggle = () => {
    this.setState({ navDrawerIsOpen: !this.state.navDrawerIsOpen })
  }

  handleClickOutside = e => {
    if (this.sidebarRef && !this.sidebarRef.current.contains(e.target)) {
      this.setState({ navDrawerIsOpen: false })
    }
  }

  render() {
    return (
      <IconContext.Provider value={{ className: "icons" }}>
        <StaticQuery
          query={graphql`
            query {
              site {
                siteMetadata {
                  title
                }
              }
            }
          `}
          render={data => (
            <>
              <AniLink
                cover
                duration={0.3}
                direction="up"
                bg="#663399"
                className={classNames({
                  "nav-brand": true,
                  "brand-blend": this.state.navDrawerIsOpen
                })}
                to="/"
              >
                <Logo className="logo" />
                <div className="site-title">{data.site.siteMetadata.title}</div>
              </AniLink>
              <label
                className={classNames({
                  "nav-toggle": true,
                  "toggle-active": this.state.navDrawerIsOpen
                })}
              >
                <input
                  type="checkbox"
                  value={this.state.navDrawerIsOpen}
                  onChange={this.navToggle}
                />
                {this.state.navDrawerIsOpen ? <FaTimes /> : <FaBars />}
              </label>
              <nav
                className={classNames({
                  "nav-drawer": true,
                  "drawer-open": this.state.navDrawerIsOpen
                })}
                ref={this.sidebarRef}
              >
                <div className="nav-item">
                  <AniLink
                    swipe
                    duration={0.3}
                    entryOffset={100}
                    direction="left"
                    className="nav-link"
                    activeClassName="active"
                    to="/"
                  >
                    Home
                  </AniLink>
                </div>
                <div className="nav-item">
                  <AniLink
                    swipe
                    duration={0.3}
                    entryOffset={100}
                    direction="left"
                    className="nav-link"
                    activeClassName="active"
                    to="/signing-day-summit"
                  >
                    Signing Day Summit
                  </AniLink>
                </div>
                {/* --------------------------------------------------------- */}
                <div className="nav-category">Explore my work</div>
                <div className="nav-item">
                  <AniLink
                    swipe
                    duration={0.3}
                    entryOffset={100}
                    direction="left"
                    className="nav-link"
                    activeClassName="active"
                    partiallyActive={true}
                    to="/blog"
                  >
                    Blog
                  </AniLink>
                </div>
                <div className="nav-item">
                  <AniLink
                    swipe
                    duration={0.3}
                    entryOffset={100}
                    direction="left"
                    className="nav-link"
                    activeClassName="active"
                    partiallyActive={true}
                    to="/podcast"
                  >
                    Podcast
                  </AniLink>
                </div>
              </nav>
            </>
          )}
        />
        <main
          className={classNames({
            "main-content": true,
            "main-pushed": this.state.navDrawerIsOpen
          })}
        >
          {this.props.children}
        </main>
      </IconContext.Provider>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
